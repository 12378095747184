import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  IconButton, 
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import { ApiService } from '../services/api.service';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface Product {
  id: number;
  name: string;
}

const apiService = new ApiService();

export const PlansTable = (props: any) => {
  const {
    count = 0,
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
    refreshData
  } = props;

  const role = localStorage.getItem("role");

  const navigate = useNavigate();

  const [plan, setPlan] = useState({
    id: 0,
    productId: 0
  });

  const [open, setOpen] = useState(false);

  const handleOpen = (plan: any) => {
    setPlan(plan);
    setOpen(true);
  };

  const notify = (message: string) => toast(message);

  const deletePlan = async (id: number) => {
    Swal.fire({
      title: "Are you sure you want to remove this plan?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        apiService.delete("/plans/" + id).then(response => {
          if (response?.data.success) {
            refreshData();

            notify(response.data.message);
          } else {
            notify(response.data.message);
          }
        });
      }
    });
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #FAFAFA',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <Card>
      <ToastContainer />
      <Box sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Customer</b>
              </TableCell>
              <TableCell>
                <b>Product</b>
              </TableCell>
              <TableCell>
                <b>Status</b>
              </TableCell>
              <TableCell>
                <b>Created at</b>
              </TableCell>
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((plan: any) => {
              return (
                <TableRow
                  hover
                  key={plan.id}
                >
                  <TableCell>
                    <Stack
                      alignItems="center"
                      direction="row"
                      spacing={2}
                    >                        
                      <Typography variant="subtitle2">
                        {plan.customerEmail}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    {plan.productName}
                  </TableCell>
                  <TableCell>
                    {plan.status}
                  </TableCell>
                  <TableCell>
                    {plan.createdAt}
                  </TableCell>
                  <TableCell>
                    <IconButton>
                      <EditIcon onClick={() => navigate(`/plan-details/${plan.id}`)} />
                    </IconButton>
                    { role == 'Admin' && (
                      <IconButton onClick={() => deletePlan(plan.id)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

PlansTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  refreshData: PropTypes.func
};