import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Container, Stack, Switch, TextField, Typography } from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ClearIcon from '@mui/icons-material/Clear';
import Sidenav from "../../components/Sidenav";
import { v4 as uuidv4 } from 'uuid';
import { ApiService } from "../../services/api.service";

const apiService = new ApiService();

interface Product {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}

interface Form {
  name: string;
  description: string;
  sections: Section[];
}

interface Section {
  uuid: string;
  name: string;
  isHidden: boolean;
  questions: Question[];
}

interface Question {
  uuid: string;
  question: string;
  questionType: string;
  answers: answer[];
  isRequired: boolean;
  hasConditionalSection: boolean;
}

interface answer {
  answer: string;
  associatedSection: string;
}

const Form = () => {

  // 1 - Remove sectionContent
  // 2 - Remove questionData
  // 3 - Remove form (setForm)

  const role = localStorage.getItem('role');
  
  const [products, setProducts] = useState<Product[]>([]);

  const [formData, setFormData] = useState<Form>({
    "name": "",
    "description": "",
    "sections": []
  });

  const [sectionContent, setSectionContent] = useState<Section[]>([]);
  const [textField, setTextField] = useState('');
  const [product, setProduct] = useState('');
  
  const getProducts = async () => {
    try {
      const response = await apiService.get('/products');
      setProducts(response?.data);
    } catch (error) {
      console.error("Error getting products data:", error);
    }
  }

  useEffect(() => {
    getProducts();
  }, []);

  const navigate = useNavigate();

  const handleFormDataChange = (event: any) => {
    const value = event.target.value;
    
    setFormData({
      ...formData,
      [event.target.name]: value
    });
  };

  const editSectionName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const { value } = event.target;
    const updatedFormData = { ...formData };
  
    updatedFormData.sections = updatedFormData.sections.map((section, i) => 
      i === index ? { ...section, name: value } : section
    );
  
    setFormData(updatedFormData);  
    setSectionContent(updatedFormData.sections);
  };

  const editQuestion = (sectionUuid: string, questionUuid: string, fieldLabel: string) => {
    if (!formData) return;
  
    const updatedSections = formData.sections.map(section => {
      if (section.uuid === sectionUuid) {
        return {
          ...section,
          questions: section.questions.map(question => 
            question.uuid === questionUuid ? { ...question, question: fieldLabel } : question
          )
        };
      }
      return section;
    });
  
    setFormData({
      ...formData,
      sections: updatedSections
    });
  };

  const editQuestionType = (sectionUuid: string, questionUuid: string, newType: string) => {
    if (!formData) return;
  
    const updatedSections = formData.sections.map(section => {
      if (section.uuid === sectionUuid) {
        return {
          ...section,
          questions: section.questions.map(question => 
            question.uuid === questionUuid ? { ...question, questionType: newType } : question
          )
        };
      }

      return section;
    });
  
    setFormData({
      ...formData,
      sections: updatedSections
    });
  };

  const setAnswerSection = (sectionUuid: string, questionUuid: string, answerIndex: any, associatedSection: string) => {
    if (!formData) return;
  
    const updatedSections = formData.sections.map(section => {
      if (section.uuid === sectionUuid) {
        const questions = [...section.questions];
        const questionIndex = questions.findIndex(question => question.uuid === questionUuid);

        if (questionIndex > -1) {
          const selectedAnswer = questions[questionIndex].answers[answerIndex];
                
          if (selectedAnswer) {
            selectedAnswer.associatedSection = associatedSection;
          }
        }

        return { ...section, questions };
      }

      return section;
    });

    setFormData({
      ...formData,
      sections: updatedSections
    });
  };
  
  const addAnswerOption = (sectionUuid: string, questionUuid: string, option: string) => {
    const updatedSections = formData.sections.map(section => {
      if (section.uuid === sectionUuid) {
        const questions = [...section.questions];
        const questionIndex = questions.findIndex(question => question.uuid === questionUuid);

        if (questionIndex > -1 && option && option != "") {
          const answer = {
            answer: option,
            associatedSection: ''
          }
    
          questions[questionIndex].answers.push(answer);
        }

        return { ...section, questions };
      }

      return section;
    });

    setFormData({
      ...formData,
      sections: updatedSections
    });
  }

  const setRequiredStatus = (sectionUuid: string, questionUuid: string) => {
    if (!formData) return;
  
    const updatedSections = formData.sections.map(section => {
      if (section.uuid === sectionUuid) {
        return {
          ...section,
          questions: section.questions.map(question =>
            question.uuid === questionUuid ? { ...question, isRequired: (question.isRequired) ? false : true } : question
          )
        };
      }
  
      return section;
    });
  
    setFormData({
      ...formData,
      sections: updatedSections
    });
  };

  // FIX it (ask chat gpt) //

  const setConditionalSection = (sectionUuid: string, questionUuid: string) => {
    const updatedSections = formData.sections.map(section => {
      if (section.uuid === sectionUuid) {
        const questions = [...section.questions];
        const questionIndex = questions.findIndex(question => question.uuid === questionUuid);

        if (questionIndex > -1) {
          questions[questionIndex].hasConditionalSection = (questions[questionIndex].hasConditionalSection) ? false : true;
        }

        return { ...section, questions };
      }

      return section;
    });

    setFormData({
      ...formData,
      sections: updatedSections
    });
  }

  /*const setConditionalSection = (fieldName: string) => {
    const questions = [...questionData];
    const questionIndex = questions.findIndex(question => question.uuid === fieldName)

    questions[questionIndex].hasConditionalSection = (questions[questionIndex].hasConditionalSection) ? false : true;
    setQuestionData(questions);
  }*/

  const addSection = () => {
    const newSection = {
      uuid: uuidv4(),
      name: '',
      isHidden: false,
      questions: []
    };

    setFormData(prevFormData => ({
      ...prevFormData,
      sections: [...prevFormData.sections, newSection]
    }));
  };

  const addQuestion = (sectionIndex: number) => {
    const newQuestion = {
      id: 0,
      uuid: uuidv4(),
      question: "",
      questionType: "shortAnswer",
      answers: [],
      isRequired: false,
      hasConditionalSection: false,
    };
  
    const updatedSections = [...formData.sections];
    
    updatedSections[sectionIndex] = {
      ...updatedSections[sectionIndex],
      questions: [...updatedSections[sectionIndex].questions, newQuestion],
    };
  
    setFormData(prevFormData => ({
      ...prevFormData,
      sections: updatedSections,
    }));
  };  

  const deleteQuestion = (sectionIndex: number, questionUuid: string) => {
    const updatedFormData = { ...formData };
  
    updatedFormData.sections = updatedFormData.sections.map((section, index) => {
      if (index === sectionIndex) {
        return {
          ...section,
          questions: section.questions.filter(question => question.uuid !== questionUuid)
        };
      }
      return section;
    });
  
    setFormData(updatedFormData);
  
    setSectionContent(updatedFormData.sections);
  };

  const createForm = async () => {
    const body = {
      productId: Number(product), 
      name: formData.name,
      description: formData.description,
      sections: formData.sections
    }

    try {
      await apiService.post("/forms", body).then((data) => {
        if (data) {
          navigate("/forms");
        }
      });
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const setProductType = (productId: string) => {
    setProduct(productId);
  }

  const titleStyle = {
    marginBottom: '20px'
  }

  const style = {
    borderLeft: '4px solid #003D6E',
    borderRadius: '3px',
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)',
    marginBottom: '20px',
    padding: '30px'
  }

  const formHeaderStyle = {
    backgroundColor: '#FFF',
    borderTop: '6px solid #003D6E',
    borderRadius: '3px',
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)',
    marginBottom: '20px',
    padding: '30px'
  }

  const boxStyle = {
    display: 'flex',
  }

  const alignLeftStyle = {
    flex: '1'
  }

  const alignRightStyle = {
    marginRight: '20px'
  }

  const alignRightWithBigMarginStyle = {
    marginRight: '30%'
  }

  const deleteQuestionStyle = {
    marginRight: '20px',
    marginBottom: '-5px',
    cursor: 'pointer'
  }

  const textAreaStyle = {
    width: '70%',
    padding: '0.375rem 1.75rem 0.375rem 0.75rem',
    fontSize: '1.2rem',
    fontFamily: 'Roboto',
    borderRadius: '3px'
  }

  const selectProductStyle = {
    display: 'inline-block',
    width: '300px',
    height: '50px',
    padding: '0.375rem 1.75rem 0.375rem 0.75rem',
    fontSize: '1.2rem',
    fontFamily: 'Roboto',
    lineHeight: '1.5',
    color: '#495057',
    verticalAlign: 'middle',
    background: '#fff',
    borderRadius: '3px',
    marginBottom: '5px'
  }

  const selectStyle = {
    display: 'inline-block',
    width: '200px',
    height: '40px',
    padding: '0.375rem 1.75rem 0.375rem 0.75rem',
    fontSize: '1.2rem',
    fontFamily: 'Roboto',
    lineHeight: '1.5',
    color: '#495057',
    verticalAlign: 'middle',
    background: '#fff',
    borderRadius: '3px',
    marginBottom: '5px'
  }

  const inputStyle = {
    width: '70%',
    marginBottom: '15px'
  }

  const inputTitleStyle = {
    width: '70%',
    marginBottom: '45px'
  }

  const labelStyle = {
    marginLeft: '5px',
    marginBottom: '5px'
  }

  const buttonStyle = {
    marginLeft: '10px'
  }

  const optionsStyle = {
    marginBottom: '15px'
  }
 
  return (
    <>
      <Sidenav />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 10, 
          bgcolor: '#F0F1F5'
        }}
      >
        <Container maxWidth="xl">
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Stack spacing={1}>
              <Typography variant="h4">
                Form Builder
              </Typography>
              <Stack alignItems="center" direction="row" spacing={1}></Stack>
            </Stack>
            <div>
              <Button 
                onClick={() => {navigate("/forms")}} 
                startIcon={<ClearIcon />} 
                variant="contained"
                sx={{
                  backgroundColor: '#003D6E',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#005F8C',
                  },
                }}
              >
                Cancel
              </Button>
            </div>
          </Stack>
          <Stack style={formHeaderStyle}>
            <select style={selectProductStyle} onChange={(event) => setProductType(event.target.value)}>
              <option value="">Select product</option>
              {
                products.map((product) => (
                  <option value={product.id}>{product.name}</option>
                ))
              }
            </select>
            <br />
            <TextField 
              name="name" 
              value={formData.name} 
              variant="outlined" style={inputStyle} 
              placeholder="Untitled Form" 
              onChange={handleFormDataChange} 
            />
            <TextareaAutosize 
              name="description" 
              value={formData.description} 
              style={textAreaStyle} 
              minRows={5} 
              placeholder="Form Description" 
              onChange={handleFormDataChange} 
            />
          </Stack>
          {
            formData.sections.map((section, index) => (
              <div key={index}>
                <Stack style={formHeaderStyle}>
                  <TextField 
                    name="name" 
                    value={section.name} 
                    variant="outlined" 
                    style={inputTitleStyle} 
                    placeholder="Untitled Section" 
                    onChange={(event) => editSectionName(event, index)} 
                  />
                  <Stack>
                    {
                      section.questions.map((question) => {
                        if (question.uuid != "") {
                          return (
                            <div style={style}>
                              <div style={boxStyle}>
                                <div key={question.uuid} style={alignLeftStyle}>
                                  <TextField 
                                    id="outlined-basic" 
                                    variant="outlined" 
                                    value={question.question} 
                                    style={inputStyle} 
                                    onChange={(event) => editQuestion(section.uuid, question.uuid, event.target.value)} 
                                  />
                                </div>
                                <div style={alignRightStyle}>
                                  <select style={selectStyle} onChange={(event) => editQuestionType(section.uuid, question.uuid, event.target.value)}>
                                    <option value="shortAnswer">Short Answer</option>
                                    <option value="paragraph">Paragraph</option>
                                    <option value="singleChoice">Single Choice</option>
                                    <option value="multipleChoice">Multiple Choice</option>
                                    <option value="file">File</option>
                                  </select>
                                </div>
                              </div>
                              <div>
                                {
                                  question.questionType == "shortAnswer" && 
                                  <TextField 
                                    id="outlined-basic" 
                                    variant="outlined" 
                                    style={inputStyle} 
                                    placeholder={"Customer answer"} 
                                    inputProps={
                                      { readOnly: true }
                                    }
                                  />
                                }
                                {
                                  question.questionType == "paragraph" && 
                                  <TextareaAutosize 
                                    style={textAreaStyle} 
                                    minRows={5} 
                                    placeholder={"Customer answer"} 
                                  />
                                }
                                {
                                  question.questionType == "singleChoice" && 
                                  <div>
                                    <div style={optionsStyle}>
                                      {
                                        question.answers.map((item, answerIndex) => 
                                          <div style={boxStyle}>
                                            <div style={alignLeftStyle}>
                                              <input type="radio" name={question.uuid} key={item.answer} value={item.answer} />
                                              <label style={labelStyle}>{item.answer}</label>
                                            </div>
                                              {
                                                (question.hasConditionalSection || item.associatedSection != '') && 
                                                <div style={alignRightWithBigMarginStyle}>
                                                  <select style={selectStyle} onChange={(event) => setAnswerSection(section.uuid, question.uuid, answerIndex, event.target.value)}>
                                                    <option value="">Select Section</option>
                                                    {
                                                      sectionContent.filter((section, i) => i !== index).map((sectionName) => (
                                                        sectionName.uuid == item.associatedSection ? 
                                                          <option value={sectionName.uuid}>Go to {sectionName.name}</option>
                                                        :
                                                          <option value={sectionName.uuid} selected={true}>Go to {sectionName.name}</option>
                                                      ))
                                                    }
                                                  </select>
                                                </div>
                                              }
                                          </div>
                                      )}
                                    </div>
                                    <div>
                                      <TextField 
                                        id="outlined-basic" 
                                        variant="outlined" 
                                        style={inputStyle} 
                                        value={textField} 
                                        placeholder="Add an option" 
                                        onChange={(event) => setTextField(event.target.value)} 
                                      />
                                      <Button 
                                        startIcon={<AddIcon />} 
                                        variant="contained" 
                                        style={buttonStyle}
                                        onClick={() => addAnswerOption(section.uuid, question.uuid, textField)}
                                        sx={{
                                          backgroundColor: '#003D6E',
                                          color: 'white',
                                          '&:hover': {
                                            backgroundColor: '#005F8C',
                                          },
                                        }}
                                      >
                                        Add option
                                      </Button>
                                    </div>
                                  </div>
                                }
                                {
                                  question.questionType == "multipleChoice" && 
                                  <div>
                                    <div style={optionsStyle}>
                                      {
                                        question.answers.map((item) => 
                                          <div>
                                            <input type="checkbox" name={question.uuid} key={item.answer} value={item.answer} />
                                            <label style={labelStyle}>{item.answer}</label>
                                          </div>
                                        )}
                                    </div>
                                    <div>
                                      <TextField 
                                        id="outlined-basic" 
                                        variant="outlined" 
                                        style={inputStyle} 
                                        value={textField} 
                                        placeholder="Add an option" 
                                        onChange={(event) => setTextField(event.target.value)}
                                      />
                                      <Button 
                                        startIcon={<AddIcon />} 
                                        variant="contained" 
                                        style={buttonStyle}
                                        onClick={() => addAnswerOption(section.uuid, question.uuid, textField)}
                                        sx={{
                                          backgroundColor: '#003D6E',
                                          color: 'white',
                                          '&:hover': {
                                            backgroundColor: '#005F8C',
                                          },
                                        }}
                                      >
                                        Add option
                                      </Button>
                                    </div>
                                  </div>
                                }
                                {
                                  question.questionType == "file" && 
                                  <div>
                                    <TextField type="file" />
                                  </div>
                                }
                              </div>
                              <div style={boxStyle}>
                                <div style={alignLeftStyle}>
                                  <span>Required</span>
                                  { question.isRequired ? (
                                      <>
                                        <Switch 
                                          onClick={() => setRequiredStatus(section.uuid, question.uuid)} 
                                          checked 
                                          sx={{
                                            '& .MuiSwitch-switchBase.Mui-checked': {
                                              color: '#003D6E'
                                            },
                                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                              backgroundColor: '#003D6E'
                                            }
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <Switch 
                                        onClick={() => setRequiredStatus(section.uuid, question.uuid)} 
                                        sx={{
                                          '& .MuiSwitch-switchBase.Mui-checked': {
                                            color: '#003D6E'
                                          },
                                          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                            backgroundColor: '#003D6E'
                                          }
                                        }}
                                      />
                                    )
                                  }
                                </div>
                                {
                                  question.questionType == "singleChoice" && 
                                  <div style={alignLeftStyle}>
                                    Go to section based on answer 
                                    <Switch 
                                      onClick={() => setConditionalSection(section.uuid, question.uuid)} 
                                      sx={{
                                        '& .MuiSwitch-switchBase.Mui-checked': {
                                          color: '#003D6E'
                                        },
                                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                          backgroundColor: '#003D6E'
                                        }
                                      }}
                                    />
                                  </div>
                                }
                                <div style={alignRightStyle}>
                                  <DeleteForeverIcon 
                                    style={deleteQuestionStyle} 
                                    onClick={() => deleteQuestion(index, question.uuid)} 
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })
                    }
                    <div className="add-button">
                      <br />
                      <Button 
                        startIcon={<AddIcon />} 
                        variant="contained" 
                        onClick={() => {addQuestion(index)}}
                        sx={{
                          backgroundColor: '#003D6E',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: '#005F8C',
                          },
                        }}
                      >
                        Add Question
                      </Button>
                    </div>
                  </Stack>
                </Stack>
              </div>
            ))
          }
          <Button 
            onClick={addSection} 
            startIcon={<AddIcon />} 
            variant="contained"
            sx={{
              backgroundColor: '#003D6E',
              color: 'white',
              '&:hover': {
                backgroundColor: '#005F8C',
              },
            }}
          >
            Add Section
          </Button>
          <Button 
            onClick={() => createForm()} 
            startIcon={<DoneIcon />} 
            variant="contained" 
            style={buttonStyle}
            sx={{
              backgroundColor: '#003D6E',
              color: 'white',
              '&:hover': {
                backgroundColor: '#005F8C',
              },
            }}
          >
            Save Form
          </Button>
        </Container>
      </Box>
    </>
  );
}

export default Form;