import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";

interface TaskProps {
  title: string;
  index: number;
  parent: string;
}

export const Task = ({ title, index, parent }: TaskProps) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: title,
    data: {
      title,
      index,
      parent,
    },
  });

  const style: React.CSSProperties = {
    transform: CSS.Translate.toString(transform),
    height: '200px',
    width: '320px',
    padding: '10px',
    marginTop: '10px',
    marginBottom: '10px',
    borderRadius: '8px',
    backgroundColor: 'white',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    flexWrap: "wrap",
    wordWrap: "break-word",
    wordBreak: "break-all",
    overflowY: 'auto'
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    >
      {title}
    </div>
  );
};
