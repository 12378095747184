import axios, { AxiosInstance } from "axios";

export class ApiService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: 'https://api.secondchancefinancials.com'
      //baseURL: 'http://localhost:3000'
    });

    this.axiosInstance.interceptors.request.use((config) => {
      const token = localStorage.getItem('accessToken');
      
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      
      return config;
    }, (error) => {
      return Promise.reject(error);
    });
  }

  async get(endpoint: string) {
    try {
      const response = await this.axiosInstance.get(endpoint);
      
      if (response) {
        return response;
      }
    } catch (error: any) {
      console.error("Request error:", error);

      if (error.response && error.response.status === 403) {
        return error.response;
      }

      throw error;
    }
  }

  async post(endpoint: string, body: any) {
    try {
      const response = await this.axiosInstance.post(endpoint, body);
      
      if (response) {
        return response;
      }
    } catch (error: any) {
      console.error("Request error:", error);

      if (error.response && error.response.status === 403) {
        return error.response;
      }
      
      throw error;
    }
  }

  async patch(endpoint: string, body: any) {
    try {
      const response = await this.axiosInstance.patch(endpoint, body);
      
      if (response) {
        return response;
      }
    } catch (error: any) {
      console.error("Request error:", error);

      if (error.response && error.response.status === 403) {
        return error.response;
      }
      
      throw error;
    }
  }

  async delete(endpoint: string) {
    try {
      const response = await this.axiosInstance.delete(endpoint);
      
      if (response) {
        return response;
      }
    } catch (error: any) {
      console.error("Request error:", error);

      if (error.response && error.response.status === 403) {
        return error.response;
      }
      
      throw error;
    }
  }
}