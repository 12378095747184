import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Customers from "./pages/Customers";
import Products from "./pages/Products";
import Forms from "./pages/Forms";
import FormBuilder from "./pages/FormBuilder/formBuilder";
import Form from "./pages/Forms/form";
import Applications from "./pages/Applications";
import Application from "./pages/Applications/application";
import Users from "./pages/Users";
import Dashboard from "./pages/Dashboard";
import RessetPassword from "./pages/ResetPassword";
import ApplicationsBoard from "./pages/ApplicationsBoard";
import Plans from "./pages/Plans";
import PlanDetails from "./pages/PlanDetails";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element = { <Login /> } />
        <Route path="/signup" element = { <Register /> } />
        <Route path="/customers" element = { <Customers /> } />
        <Route path="/users" element = { <Users /> } />
        <Route path="/products" element = { <Products /> } />
        <Route path="/forms" element = { <Forms /> } />
        <Route path="/form-builder" element = { <FormBuilder /> } />
        <Route path="/form/:formId" element={ <Form /> } />
        <Route path="/applications" element={ <Applications /> } />
        <Route path="/applications/:applicationId" element={ <Application /> } />
        <Route path="/dashboard" element = { <Dashboard /> } />
        <Route path="/reset-password/:token" element = { <RessetPassword /> } />
        <Route path="/applications-board" element = { <ApplicationsBoard /> } />
        <Route path="/plans" element = { <Plans /> } />
        <Route path="/plan-details/:planId" element = { <PlanDetails /> } />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRoutes;