import { DndContext, rectIntersection } from "@dnd-kit/core";
import { useEffect, useState } from "react";
import { TaskColumn } from "../TaskColumn";
import { Card } from "../../types/card";
import tasksData from '../../tasks.json';

export default function TaskBoard() {
  const [formsSentItems, setFormsSentItems] = useState<Card[]>([]);
  const [meetingBookedItems, setMeetingBookedItems] = useState<Card[]>([]);
  const [waitingForInfoItems, setWaitingForInfoItems] = useState<Card[]>([]);
  const [readyToApplyItems, setReadyToApplyItems] = useState<Card[]>([]);
  const [applicationInProgressItems, setApplicationInProgressItems] = useState<Card[]>([]);
  const [awaitingForApprovalItems, setAwaitingForApprovalItems] = useState<Card[]>([]);
  const [pendencyUnderwritingItems, setPendencyUnderwritingItems] = useState<Card[]>([]);
  const [waitingClientAcceptanceItems, setWaitingClientAcceptanceItems] = useState<Card[]>([]);
  const [inIssuingItems, setInIssuingItems] = useState<Card[]>([]);

  useEffect(() => {
    setFormsSentItems(tasksData.formsSent);
    setReadyToApplyItems(tasksData.meetingBooked);
    setMeetingBookedItems(tasksData.waitingForInfo);
    setReadyToApplyItems(tasksData.readyToApply);
    setApplicationInProgressItems(tasksData.applicationInProgress);
    setAwaitingForApprovalItems(tasksData.awaitingForApprovalItems);
    setPendencyUnderwritingItems(tasksData.pendencyUnderwritingItems);
    setWaitingClientAcceptanceItems(tasksData.waitingClientAcceptanceItems);
    setInIssuingItems(tasksData.inIssuingItems);
  }, []);

  const handleDragEnd = (event: any) => {
    const container = event.over?.id;
    const title = event.active.data.current?.title ?? "";
    const index = event.active.data.current?.index ?? 0;
    const parent = event.active.data.current?.parent ?? "Unassigned";

    if (!container || container === parent) {
      return;
    }

    // Add item to the new column
    if (container === "Forms sent") {
      setFormsSentItems([...formsSentItems, { title }]);
    } else if (container === "Meeting booked") {
      setMeetingBookedItems([...meetingBookedItems, { title }]);
    } else if (container === "Waiting for info") {
      setWaitingForInfoItems([...waitingForInfoItems, { title }]);
    } else if (container === "Ready to apply") {
      setReadyToApplyItems([...readyToApplyItems, { title }]);
    } else if (container === "Application in progress") {
      setApplicationInProgressItems([...applicationInProgressItems, { title }]);
    } else if (container === "Awaiting for approval") {
      setAwaitingForApprovalItems([...awaitingForApprovalItems, { title }]);
    } else if (container === "Pendency underwriting") {
      setPendencyUnderwritingItems([...pendencyUnderwritingItems, { title }]);
    } else if (container === "Waiting client acceptance") {
      setWaitingClientAcceptanceItems([...waitingClientAcceptanceItems, { title }]);
    } else if (container === "In issuing") {
      setInIssuingItems([...inIssuingItems, { title }]);
    }

    // Remove item from the previous column
    if (parent === "Forms sent") {
      setFormsSentItems(formsSentItems.filter((_, i) => i !== index));
    } else if (parent === "Meeting booked") {
      setMeetingBookedItems(meetingBookedItems.filter((_, i) => i !== index));
    } else if (parent === "Waiting for info") {
      setWaitingForInfoItems(waitingForInfoItems.filter((_, i) => i !== index));
    } else if (parent === 'Ready to apply') {
      setReadyToApplyItems(readyToApplyItems.filter((_, i) => i !== index));
    } else if (parent === 'Application in progress') {
      setApplicationInProgressItems(applicationInProgressItems.filter((_, i) => i !== index));
    } else if (parent === 'Awaiting for approval') {
      setAwaitingForApprovalItems(awaitingForApprovalItems.filter((_, i) => i !== index));
    } else if (parent === 'Pendency underwriting') {
      setPendencyUnderwritingItems(pendencyUnderwritingItems.filter((_, i) => i !== index));
    } else if (parent === 'Waiting client acceptance') {
      setWaitingClientAcceptanceItems(waitingClientAcceptanceItems.filter((_, i) => i !== index));
    } else if (parent === 'In issuing') {
      setInIssuingItems(inIssuingItems.filter((_, i) => i !== index));
    }
  };

  return (
    <DndContext
      collisionDetection={rectIntersection}
      onDragEnd={handleDragEnd}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div style={{ display: 'flex', flex: 3, overflowX: 'auto', padding: '10px', margin: '0 -100px', marginRight: '100px' }}>
          <TaskColumn title="Forms sent" items={formsSentItems} />
          <TaskColumn title="Meeting booked" items={meetingBookedItems} />
          <TaskColumn title="Waiting for info" items={waitingForInfoItems} />
          <TaskColumn title="Ready to apply" items={readyToApplyItems} />
          <TaskColumn title="Application in progress" items={applicationInProgressItems} />
          <TaskColumn title="Awaiting for approval" items={awaitingForApprovalItems} />
          <TaskColumn title="Pendency underwriting" items={pendencyUnderwritingItems} />
          <TaskColumn title="Waiting client acceptance" items={waitingClientAcceptanceItems} />
          <TaskColumn title="In issuing" items={inIssuingItems} />
        </div>
      </div>
    </DndContext>
  );
}
