import { useDroppable } from "@dnd-kit/core";
import { Card } from "../../types/card";
import { Task } from "../Task";

interface TaskColumnProps {
  title: string;
  items: Card[];
}

export function TaskColumn({ title, items }: TaskColumnProps) {
  const { setNodeRef } = useDroppable({
    id: title,
  });

  return (
    <div style={{ 
      flex: 3, 
      padding: '15px', 
      minHeight: '200px', 
      minWidth: '360px', 
      backgroundColor: '#F2F2F2', 
      borderRadius: '8px', 
      borderTop: '5px solid #003D6E',
      borderBottom: '1px solid #DDD',
      borderLeft: '1px solid #DDD',
      borderRight: '1px solid #DDD',
      margin: '0 5px', 
      color: '#003D6E',
      fontSize: '12px'
    }}>
      <h2 style={{ fontWeight: 'bold' }}>{title}</h2>
      <div ref={setNodeRef} style={{ 
        backgroundColor: '#F2F2F2', 
        borderRadius: '8px', 
        padding: '5px', 
        height: '90%',
        flex: 1 
      }}>
        {items.map((item, key) => (
          <Task title={item.title} key={key} index={key} parent={title} />
        ))}
      </div>
    </div>
  );
}
